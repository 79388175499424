import React, { useState, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { motion, AnimatePresence } from 'framer-motion';
import surveyData from './questions.js';
import { jsPDF } from 'jspdf';
import {  Sparkles } from 'lucide-react';
import AICarePlan from './AICarePlan';


const SECURE_PDF_PASSWORD = "CFHDC_MI_Survey_2024_Secure_Results_83932152!@"; // Fixed password for all PDFs

const PdfContent = ({ scores, answers }) => {
  // Filter out Data Collection and Personal History from scores
  const filteredScores = scores.filter(score => 
    !['Data Collection', 'Personal History'].includes(score.name)
  );

  return (
    <div className="p-10">
      <h1 className="text-3xl font-bold mb-6">{surveyData.survey.title} - Results</h1>
      <div className="mb-10">
        <h2 className="text-2xl font-bold mb-4">Detailed Scores:</h2>
        {filteredScores.map((score, index) => (
          <p key={index} className="mb-2">
            <span className="font-medium">{score.name}:</span> {score.percentage.toFixed(2)}%
          </p>
        ))}
      </div>
      <h2 className="text-2xl font-bold mb-4">Survey Answers:</h2>
      {surveyData.survey.sections.map((section) => (
        <div key={section.id} className="mb-6">
          <h3 className="text-xl font-bold mb-2">{section.name}</h3>
          {section.questions.map((question) => (
            <div key={question.id} className="mb-4">
              <p className="font-medium">{question.text}</p>
              <p>Answer: {answers[section.id]?.[question.id]}</p>
              {answers[section.id]?.[`${question.id}A`] && (
                <p>Additional Context: {answers[section.id][`${question.id}A`]}</p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
const handleExportCsv = ({ clientInfo, answers }) => {
  // Create headers
  let csvContent = "Section,Question ID,Question,Answer,Additional Context\n";

  try {
    // Process each section and its questions
    surveyData.survey.sections.forEach(section => {
      // Skip if section is undefined
      if (!section) return;

      section.questions.forEach(question => {
        // Skip additional context questions as they'll be handled with their parent question
        if (question.id.toString().includes('A')) {
          return;
        }

        // Safely check for answers
        const sectionAnswers = answers[section.id] || {};
        const answer = sectionAnswers[question.id];
        let formattedAnswer = '';

        // Format the answer based on its type
        if (answer === undefined || answer === '') {
          formattedAnswer = 'Not Answered';
        } else if (Array.isArray(answer)) {
          formattedAnswer = `"${answer.join(', ')}"`;
        } else if (typeof answer === 'boolean') {
          formattedAnswer = answer ? 'Yes' : 'No';
        } else {
          formattedAnswer = `"${answer}"`;
        }

        // Safely get additional context
        const additionalContextId = `${question.id}A`;
        const additionalContext = sectionAnswers[additionalContextId] || '';

        // Escape any quotes in text fields and wrap in quotes
        const escapedQuestion = question.text?.replace(/"/g, '""') || '';
        const escapedSection = section.name?.replace(/"/g, '""') || '';

        // Add row to CSV
        csvContent += [
          `"${escapedSection}"`,
          `"${question.id}"`,
          `"${escapedQuestion}"`,
          formattedAnswer,
          `"${additionalContext.replace(/"/g, '""')}"`
        ].join(',') + '\n';
      });
    });

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    // Create filename using client info if available
    const filename = clientInfo?.clientName 
      ? `${clientInfo.clientName}_survey_responses.csv`
      : 'survey_responses.csv';

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error generating CSV:', error);
    alert('There was an error generating the CSV file. Please try again.');
  }
};
  
const SurveyApp = () => {
    const [currentSection, setCurrentSection] = useState(-2);
    const [answers, setAnswers] = useState({});
    const [showResults, setShowResults] = useState(false);
    const [showAdditionalContext, setShowAdditionalContext] = useState({});
    const [showAIPlan, setShowAIPlan] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [clientInfo, setClientInfo] = useState({
        clientName: '',
        respondentName: '',
        surveyorName: '',
        date: new Date().toISOString().split('T')[0]
    });

   // Check if all required questions in the current section are answered
   const checkSectionCompletion = () => {
    if (currentSection < 0) return true;
    
    const section = surveyData.survey.sections[currentSection];
    const sectionId = currentSection + 1;
    
    const unansweredQuestions = section.questions.filter(question => {
        // Skip additional context questions
        if (question.id.toString().includes('A')) return false;
        
        const answer = answers[sectionId]?.[question.id];
        return answer === undefined || answer === '' || 
               (Array.isArray(answer) && answer.length === 0);
    });
    
    return unansweredQuestions.length === 0;
};

const handleNextSection = () => {
    if (checkSectionCompletion()) {
        setValidationError(false);
        if (currentSection < surveyData.survey.sections.length - 1) {
            setCurrentSection(prev => prev + 1);
        } else {
            setShowResults(true);
        }
    } else {
        setValidationError(true);
        // Scroll to top to show error message
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
};

// Check if a specific question is answered
const isQuestionAnswered = (sectionId, questionId) => {
    const answer = answers[sectionId]?.[questionId];
    return !(answer === undefined || answer === '' || 
           (Array.isArray(answer) && answer.length === 0));
};


    // Add the getIntelligenceContext function here, inside SurveyApp
    const getIntelligenceContext = (rank) => {
      // Get sorted scores using the existing calculateScores function
      const scores = calculateScores();
      
      // Get the intelligence at the specified rank (1-based ranking)
      const targetIntelligence = scores[rank - 1];
      
      if (!targetIntelligence) {
          return `No intelligence found for rank ${rank}`;
      }
      
      // Find the corresponding section
      const section = surveyData.survey.sections.find(
          section => section.name === targetIntelligence.name
      );
      
      if (!section) {
          return `No section found for intelligence: ${targetIntelligence.name}`;
      }
      
      // Build the context string
      let context = `Intelligence Type: ${targetIntelligence.name}\n`;
      context += `Score: ${targetIntelligence.percentage.toFixed(2)}%\n`;
      context += `Rank: ${rank}\n\n`;
      context += `Questions and Answers:\n`;
      
      // Add each question, answer, and additional context
      section.questions.forEach(question => {
          // Skip additional context questions as they'll be handled with their parent question
          if (question.id.toString().includes('A')) {
              return;
          }
          
          const answer = answers[section.id]?.[question.id];
          const additionalContextQuestion = section.questions.find(
              q => q.id === `${question.id}A`
          );
          const additionalContextAnswer = answers[section.id]?.[`${question.id}A`];
          
          context += `\nQ: ${question.text}\n`;
          context += `A: ${answer !== undefined ? answer : 'Not answered'}\n`;
          
          if (additionalContextQuestion && additionalContextAnswer) {
              context += `Additional Context Question: ${additionalContextQuestion.text}\n`;
              context += `Additional Context Answer: ${additionalContextAnswer}\n`;
          }
      });
      
      return context;
  };

    const handleInfoSubmit = () => {
      if (clientInfo.clientName && clientInfo.respondentName && clientInfo.surveyorName) {
          setCurrentSection(-1); // Go to introduction screen
      }
  };

  const renderInfoScreen = () => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="space-y-6"
    >
        <h2 className="text-2xl font-bold mb-6">Survey Information</h2>
        <div className="space-y-4">
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Client's Name - care facility name
                </label>
                <input
                    type="text"
                    value={clientInfo.clientName}
                    onChange={(e) => setClientInfo(prev => ({ ...prev, clientName: e.target.value }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Respondent's Name - person answering the questions for the client
                </label>
                <input
                    type="text"
                    value={clientInfo.respondentName}
                    onChange={(e) => setClientInfo(prev => ({ ...prev, respondentName: e.target.value }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Surveyor's Name - person helping the respondent with the client
                </label>
                <input
                    type="text"
                    value={clientInfo.surveyorName}
                    onChange={(e) => setClientInfo(prev => ({ ...prev, surveyorName: e.target.value }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date
                </label>
                <input
                    type="date"
                    value={clientInfo.date}
                    onChange={(e) => setClientInfo(prev => ({ ...prev, date: e.target.value }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    required
                />
            </div>
        </div>
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleInfoSubmit}
            disabled={!clientInfo.clientName || !clientInfo.respondentName || !clientInfo.surveyorName}
            className={`w-full px-6 py-3 mt-6 rounded-lg transition-colors duration-200 text-lg font-semibold
                ${(!clientInfo.clientName || !clientInfo.respondentName || !clientInfo.surveyorName)
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-blue-500 text-white hover:bg-blue-600'}`}
        >
            Next
        </motion.button>
    </motion.div>
);

  // You can use it in the renderResults function or create a new button/function to use it
  const handleExportTopIntelligences = () => {
    const firstIntelligenceContext = getIntelligenceContext(1);
    const secondIntelligenceContext = getIntelligenceContext(2);
    console.log("First Intelligence:\n", firstIntelligenceContext);
    console.log("Second Intelligence:\n", secondIntelligenceContext);
    // You can also return or use these values however you need
    return { firstIntelligenceContext, secondIntelligenceContext };
};


const renderIntroductionScreen = () => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="space-y-6"
    >
        <h2 className="text-2xl font-bold mb-6">Introduction</h2>
        <div className="prose max-w-none">
            <p className="text-gray-700 leading-relaxed">
                The following questions are based on the Theory of Multiple Intelligences, which we've adapted for persons with dementia. This survey will determine how the individual in your care learned and acquired information before their dementia diagnosis.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
                The results from this survey will be used in conjunction with other holistic interventions to design an individualized care plan to meet their specific needs.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4 font-medium">
                Caregiver or family responses should be based on observable/witnessed actions.
            </p>
        </div>
        <div className="flex justify-between mt-8">
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setCurrentSection(-2)}
                className="px-6 py-3 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors duration-200 text-lg font-semibold"
            >
                Back
            </motion.button>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setCurrentSection(0)}
                className="px-6 py-3 bg-blue-500 text-white hover:bg-blue-600 rounded-lg transition-colors duration-200 text-lg font-semibold"
            >
                Begin Survey
            </motion.button>
        </div>
    </motion.div>
);



const handleExportPdf = () => {
  // Initialize PDF with encryption
  var doc = new jsPDF({
    encryption: {
      userPassword: SECURE_PDF_PASSWORD,
      ownerPassword: SECURE_PDF_PASSWORD,
      userPermissions: ["print", "modify", "copy", "annot-forms"]
    }
  });

  const formatAnswer = (answer, questionType) => {
    if (!answer) return 'Not answered';
    
    switch (questionType) {
      case 'multiselect':
        return Array.isArray(answer) ? answer.join(', ') : answer;
      case 'select':
      case 'text':
      case 'email':
        return answer.toString();
      case 'scale':
        return `${answer}/5`;
      case 'boolean':
        return answer ? 'Yes' : 'No';
      default:
        return answer.toString();
    }
  };
  
  // Set initial position and margins
  let yPos = 20;
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - (2 * margin);

  // Helper function to check if we need a new page
  const checkNewPage = (height = 10) => {
    if (yPos + height > doc.internal.pageSize.height - margin) {
      doc.addPage();
      yPos = margin;
      return true;
    }
    return false;
  };

  // Helper function for text wrapping
  const addWrappedText = (text, x, fontSize = 12, isHeader = false) => {
    doc.setFontSize(fontSize);
    if (isHeader) doc.setFont(undefined, 'bold');
    const lines = doc.splitTextToSize(text, contentWidth - x);
    lines.forEach(line => {
      checkNewPage();
      doc.text(line, x, yPos);
      yPos += fontSize * 0.5;
    });
    yPos += 5;
    doc.setFont(undefined, 'normal');
  };

  // Title
  doc.setFontSize(18);
  doc.setFont(undefined, 'bold');
  addWrappedText("Multiple Intelligence Survey for Persons with Dementia (MISPWD) - Results", margin, 18, true);
  yPos += 10;

  // Survey Information
  addWrappedText("Survey Information:", margin, 16, true);
  addWrappedText(`Client's Name: ${clientInfo.clientName}`, margin + 5);
  addWrappedText(`Respondent's Name: ${clientInfo.respondentName}`, margin + 5);
  addWrappedText(`Surveyor's Name: ${clientInfo.surveyorName}`, margin + 5);
  addWrappedText(`Date: ${clientInfo.date}`, margin + 5);
  yPos += 10;

  // Survey Overview
  addWrappedText("Survey Overview:", margin, 16, true);
  const introText = "This survey is based on the Theory of Multiple Intelligences, adapted for persons with dementia. It determines how the individual learned and acquired information before their dementia diagnosis. Results are used with other holistic interventions to design an individualized care plan.";
  addWrappedText(introText, margin + 5);
  yPos += 10;

  // Scores Section
  addWrappedText("Detailed Scores:", margin, 16, true);
  const scores = calculateScores();
  scores.forEach(score => {
    checkNewPage();
    addWrappedText(`${score.name}: ${score.percentage.toFixed(2)}%`, margin + 5);
  });
  yPos += 15;

  // Create chart image using Chart.js or similar
  // Note: Since we can't directly render the Recharts component to an image,
  // we'll create a simpler visualization using jsPDF's built-in methods
  const chartHeight = 60;
  checkNewPage(chartHeight + 20);
  
  // Draw a simple bar chart
  const barWidth = contentWidth / scores.length;
  const maxBarHeight = 50;
  scores.forEach((score, index) => {
    const barH = (score.percentage / 100) * maxBarHeight;
    doc.setFillColor(136, 132, 216); // #8884d8 color from Recharts
    doc.rect(
      margin + (index * barWidth),
      yPos + (maxBarHeight - barH),
      barWidth - 2,
      barH,
      'F'
    );
    
    // Add label
    doc.setFontSize(8);
    doc.setTextColor(0);
    const label = doc.splitTextToSize(score.name, barWidth - 4);
    doc.text(label, margin + (index * barWidth), yPos + maxBarHeight + 10, {
      angle: 45
    });
  });
  yPos += chartHeight + 30;

  // Answers Section
  addWrappedText("Survey Answers:", margin, 16, true);
  surveyData.survey.sections.forEach((section) => {
    checkNewPage();
    addWrappedText(section.name, margin, 14, true);
  
    section.questions.forEach((question) => {
      checkNewPage();
      // Question text
      addWrappedText(question.text, margin + 5);
      
      // Answer
      const answer = answers[section.id]?.[question.id];
      if (answer !== undefined) {
        addWrappedText(`Answer: ${formatAnswer(answer, question.type)}`, margin + 10);
      }
  
      // Additional context if exists
      const additionalContext = answers[section.id]?.[`${question.id}A`];
      if (additionalContext) {
        addWrappedText(`Additional Context: ${additionalContext}`, margin + 10);
      }
      yPos += 5;
    });
    yPos += 10;
  });

  // Save the PDF
  doc.save('CenterForHolisticDementiaCare_DementiaCareIDResults.pdf');
};
  
  

    const handleAnswer = (sectionId, questionId, value) => {
      setAnswers(prev => ({
        ...prev,
        [sectionId]: {
          ...prev[sectionId],
          [questionId]: value
        }
      }));
    };
  
    const toggleAdditionalContext = (sectionId, questionId) => {
      setShowAdditionalContext(prev => ({
        ...prev,
        [sectionId]: {
          ...prev[sectionId],
          [questionId]: !prev[sectionId]?.[questionId]
        }
      }));
    };  

    const calculateScores = () => {
      // Filter out sections that should be included in scoring (excluding sections 1 and 2)
      const scorableSections = surveyData.survey.sections.filter(section => 
        section.id !== 1 && section.id !== 2 && section.questions.some(q => q.type === 'scale')
      );
    
      console.log('\n=== SCORE BREAKDOWN ===');
      
      // Calculate scores for each section
      const scores = scorableSections.map(section => {
        // Filter scale-type questions only
        const scaleQuestions = section.questions.filter(q => q.type === 'scale');
        
        // Calculate actual score (sum of all answered scale questions)
        const sectionScore = scaleQuestions.reduce((acc, question) => {
          const answer = answers[section.id]?.[question.id];
          const value = answer ? parseInt(answer) : 0;
          console.log(`Section ${section.id} - Question ${question.id}: ${value}`);
          return acc + value;
        }, 0);
    
        // Calculate maximum possible score (5 points per scale question)
        const maxPossibleScore = scaleQuestions.length * 5;
    
        // Calculate percentage
        const percentage = (sectionScore / maxPossibleScore) * 100;
    
        console.log(`\nSection ${section.id} - ${section.name}:`);
        console.log(`Total Score: ${sectionScore}`);
        console.log(`Max Possible: ${maxPossibleScore}`);
        console.log(`Percentage: ${percentage.toFixed(2)}%\n`);
    
        return {
          name: section.name,
          score: sectionScore,
          maxScore: maxPossibleScore,
          percentage: percentage
        };
      });
    
      // Calculate grand total
      const grandTotal = scores.reduce((acc, section) => acc + section.score, 0);
      const maxGrandTotal = scores.reduce((acc, section) => acc + section.maxScore, 0);
      const overallPercentage = (grandTotal / maxGrandTotal) * 100;
    
      console.log('=== OVERALL TOTALS ===');
      console.log(`Total Score Across All Sections: ${grandTotal}`);
      console.log(`Maximum Possible Total: ${maxGrandTotal}`);
      console.log(`Overall Percentage: ${overallPercentage.toFixed(2)}%`);
      console.log('=====================\n');
    
      // Sort by percentage in descending order
      return scores.sort((a, b) => b.percentage - a.percentage);
    };

  const renderQuestion = (question) => {
    const sectionId = currentSection + 1;
    const isAdditionalContext = question.id.toString().includes('A');
    const mainQuestionId = isAdditionalContext ? question.id.toString().replace('A', '') : question.id;
    const hasAdditionalContext = surveyData.survey.sections[currentSection].questions.some(q => q.id === `${mainQuestionId}A`);
  
    // If it's an additional context question, we'll only render it within the expandable section
    if (isAdditionalContext) {
      return null;
    }
  
    const questionAnswered = isQuestionAnswered(sectionId, question.id);
    const showError = validationError && !questionAnswered;

    return (
        <motion.div
          key={question.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >

          
        
        <p className={`mb-4 font-medium text-lg mt-8 ${showError ? 'text-red-500' : ''}`}>
                    {question.text}
                    {showError && <span className="ml-2 text-sm">(Required)</span>}
                </p>

          {question.type === 'scale' && (
            <>
              <div className="flex justify-between mt-2">
                {[1, 2, 3, 4, 5].map(value => (
                  <motion.button
                    key={value}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleAnswer(sectionId, question.id, value)}
                    className={`w-14 h-14 rounded-full ${
                      answers[sectionId]?.[question.id] === value
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 hover:bg-gray-300'
                    } transition-colors duration-200 text-lg font-semibold`}
                  >
                    {value}
                  </motion.button>
                ))}
              </div>
              <div className="flex justify-between mt-2 text-sm text-gray-600">
                <span>{question.minLabel}</span>
                <span>{question.maxLabel}</span>
              </div>
            </>
          )}

          {question.type === 'text' && (
            <textarea
              value={answers[sectionId]?.[question.id] || ''}
              onChange={(e) => handleAnswer(sectionId, question.id, e.target.value)}
              placeholder={question.placeholder || ''}
              className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            />
          )}

          {question.type === 'select' && (
            <select
              value={answers[sectionId]?.[question.id] || ''}
              onChange={(e) => handleAnswer(sectionId, question.id, e.target.value)}
              className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="" disabled>{question.placeholder || 'Choose an option'}</option>
              {question.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}

          {question.type === 'multiselect' && (
            <div className="space-y-2 mt-2">
              {question.options.map((option) => {
                const selectedOptions = answers[sectionId]?.[question.id] || [];
                return (
                  <div key={option} className="flex items-center p-2">
                    <input
                      type="checkbox"
                      id={`${question.id}-${option}`}
                      checked={selectedOptions.includes(option)}
                      onChange={(e) => {
                        const currentAnswers = answers[sectionId]?.[question.id] || [];
                        const newAnswers = e.target.checked
                          ? [...currentAnswers, option]
                          : currentAnswers.filter(item => item !== option);
                        handleAnswer(sectionId, question.id, newAnswers);
                      }}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label htmlFor={`${question.id}-${option}`} className="ml-2 text-gray-700">
                      {option}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        {question.type === 'email' && (
          <input
            type="email"
            value={answers[sectionId]?.[question.id] || ''}
            onChange={(e) => handleAnswer(sectionId, question.id, e.target.value)}
            placeholder="Enter email address"
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
          />
        )}

          {question.type === 'otherda' && (
            <>
              <div className="flex justify-between">
                {[1, 2, 3, 4, 5].map(value => (
                  <motion.button
                    key={value}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleAnswer(sectionId, question.id, value)}
                    className={`w-14 h-14 rounded-full ${
                      answers[sectionId]?.[question.id] === value
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 hover:bg-gray-300'
                    } transition-colors duration-200 text-lg font-semibold`}
                  >
                    {value}
                  </motion.button>
                ))}
              </div>
              <div className="flex justify-between mt-2 text-sm text-gray-600">
                <span>{question.minLabel}</span>
                <span>{question.maxLabel}</span>
              </div>
            </>
          )}
          
          {question.type === 'boolean' && (
            <div className="flex space-x-6">
              {['Yes', 'No'].map((option) => (
                <motion.button
                  key={option}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleAnswer(sectionId, question.id, option === 'Yes')}
                  className={`px-6 py-3 rounded-lg ${
                    answers[sectionId]?.[question.id] === (option === 'Yes')
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 hover:bg-gray-300'
                  } transition-colors duration-200 text-lg font-semibold`}
                >
                  {option}
                </motion.button>
              ))}
            </div>
          )}
          {hasAdditionalContext && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="mt-4"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => toggleAdditionalContext(sectionId, question.id)}
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors duration-200 text-sm font-medium"
              >
                Additional Context +
              </motion.button>
              {showAdditionalContext[sectionId]?.[question.id] && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-4"
                >
                  <p className="text-sm text-gray-600 mb-2">
                    {surveyData.survey.sections[currentSection].questions.find(q => q.id === `${question.id}A`)?.text}
                  </p>
                  <textarea
                    value={answers[sectionId]?.[`${question.id}A`] || ''}
                    onChange={(e) => handleAnswer(sectionId, `${question.id}A`, e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    rows="3"
                  />
                </motion.div>
              )}
            </motion.div>
          )}
        </motion.div>
      );
    };
    

 const renderSection = () => {
        const section = surveyData.survey.sections[currentSection];
        return (
            <AnimatePresence mode="wait">
                <motion.div
                    key={currentSection}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                >
                    {validationError && (
                        <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
                            <strong className="font-bold">Error: </strong>
                            <span className="block sm:inline">Please answer all required questions before proceeding.</span>
                        </div>
                    )}
                    
                    <h2 className="text-2xl font-bold mb-6">{section.name}</h2>
                    {section.questions.map(renderQuestion)}
                    
                    <div className="flex justify-between mt-10">
                        {currentSection > 0 && (
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => {
                                    setValidationError(false);
                                    setCurrentSection(prev => prev - 1);
                                }}
                                className="px-6 py-3 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors duration-200 text-lg font-semibold"
                            >
                                Previous
                            </motion.button>
                        )}
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={handleNextSection}
                            className="px-6 py-3 bg-blue-500 text-white hover:bg-blue-600 rounded-lg transition-colors duration-200 text-lg font-semibold"
                        >
                            {currentSection < surveyData.survey.sections.length - 1 ? 'Next' : 'Finish'}
                        </motion.button>
                    </div>
                </motion.div>
            </AnimatePresence>
        );
    };


      // Prepare data for AI Care Plan
   const prepareAIPlanData = () => {
        // Get the top two intelligences for focused recommendations
        const scores = calculateScores();
        const topIntelligences = scores.slice(0, 2);

        return {
            clientInfo: {
                name: clientInfo.clientName,
                respondent: clientInfo.respondentName,
                surveyor: clientInfo.surveyorName,
                date: clientInfo.date
            },
            intelligenceScores: scores,
            topIntelligences: topIntelligences,
            detailedAnswers: answers
        };
    };

    const renderResults = () => {
      return (
        <AnimatePresence mode="wait">
          {showAIPlan ? (
            <motion.div
              key="ai-plan"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="w-full"
            >
              <AICarePlan 
                data={prepareAIPlanData()}
                onClose={() => setShowAIPlan(false)}
              />
            </motion.div>
          ) : (
            <motion.div
              key="results"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="w-full"
            >
              <div className="bg-white rounded-2xl shadow-xl p-10">
                <div className="text-center max-w-lg mx-auto mb-8">
                  <h3 className="text-2xl font-bold mb-4">Results Ready for Review</h3>
                  <p className="mb-6 text-lg">
                    Download the results file and email it to our team at{' '}
                    <span className="text-blue-600">melissamirabello@cfhdc.com</span>{' '}
                    for further review and creation of a care plan.
                  </p>
                  <div className="space-y-4">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleExportPdf}
                      className="w-full px-8 py-4 bg-blue-500 text-white hover:bg-blue-600 rounded-lg transition-colors duration-200 text-lg font-semibold shadow-lg flex items-center justify-center space-x-2"
                    >
                      <span>Download Secured PDF Results</span>
                    </motion.button>

                    {/*<motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleExportCsv}
                  className="w-full px-8 py-4 bg-gray-500 text-white hover:bg-gray-600 rounded-lg transition-colors duration-200 text-lg font-semibold shadow-lg flex items-center justify-center space-x-2"
                >
                  <span>Export Raw Data (CSV)</span>
                </motion.button> */}
                    {/*<motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setShowAIPlan(true)}
                      className="w-full px-8 py-4 bg-purple-500 text-white hover:bg-purple-600 rounded-lg transition-colors duration-200 text-lg font-semibold shadow-lg flex items-center justify-center space-x-2"
                    >
                      <Sparkles className="w-6 h-6" />
                      <span>Create AI Care Plan</span>
                    </motion.button>*/}
                  </div>
                </div>
                
                {/* Graph Section - with blur */}
                <div className="filter blur-md w-full h-[600px] mb-8 opacity-50">
                  <ResponsiveContainer>
                    <BarChart 
                      data={calculateScores().filter(score => 
                        !['Data Collection', 'Personal History'].includes(score.name)
                      )} 
                      margin={{ top: 20, right: 30, left: 60, bottom: 100 }}
                    >
                      <XAxis 
                        dataKey="name" 
                        angle={-45} 
                        textAnchor="end" 
                        height={100} 
                        interval={0} 
                        tick={{fontSize: 12}}
                      />
                      <YAxis 
                        domain={[0, 100]}
                        ticks={[0, 20, 40, 60, 80, 100]}
                        tickFormatter={(value) => `${value}%`}
                      />
                      <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Bar dataKey="percentage" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      );
    };

  return (
    <div className="min-h-screen bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto bg-white rounded-2xl shadow-xl p-10">
            <div className="flex justify-center mb-8">
                <img src="/cde-retina.png" alt="CDE Logo" className="h-16" />
            </div>
            <h1 className="text-3xl font-bold mb-10 text-center">{surveyData.survey.title}</h1>
            {currentSection === -2 ? renderInfoScreen() :
             currentSection === -1 ? renderIntroductionScreen() :
             showResults ? renderResults() : renderSection()}
        </div>
    </div>
);
};

export default SurveyApp;