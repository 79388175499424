import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Brain, BarChart2 as ChartBarIcon, LightbulbIcon, ListChecks, ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types'; 


const AICarePlan = ({ data, onClose }) => {
  const [activeTab, setActiveTab] = useState("overview");
  const [aiAnalysis, setAiAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Use the passed data instead of hardcoded values
  const clientInfo = data.clientInfo;
  const allIntelligences = data.intelligenceScores || [];
  const topIntelligences = data.topIntelligences || [];

  const tabItems = [
      { id: "overview", label: "Overview", icon: Brain },
      { id: "analysis", label: "Analysis", icon: ChartBarIcon },
      { id: "recommendations", label: "Recommendations", icon: LightbulbIcon },
      { id: "intelligences", label: "All Intelligences", icon: ListChecks }
  ];

    /*
  useEffect(() => {
    let isSubscribed = true;

    const fetchAIAnalysis = async () => {
      if (activeTab !== "analysis" || aiAnalysis) return;
      
      // Validate required data
      if (!topIntelligences?.[0] || !topIntelligences?.[1]) {
        console.error('Missing intelligence data');
        return;
      }

      setIsLoading(true);
      try {
        const analysisPrompt = {
          guardianName: clientInfo.respondent,
          patientName: clientInfo.name,
          topIntelligence1: topIntelligences[0].name,
          topIntelligence1Percentage: `${topIntelligences[0].percentage.toFixed(0)}%`,
          topIntelligence2: topIntelligences[1].name,
          topIntelligence2Percentage: `${topIntelligences[1].percentage.toFixed(0)}%`,
          patientContextQuestionsIntelligence1: data.getIntelligenceContext?.(1) || "No context available",
          patientContextQuestionsIntelligence2: data.getIntelligenceContext?.(2) || "No context available"
        };

        console.log('Sending analysis prompt:', analysisPrompt); // Debug log
        
  //CHANGE FOR PROD - https://cors-anywhere.herokuapp.com/https://api.anthropic.com/v1/messages
        
const response = await fetch('', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'anthropic-version': '2024-01-01'
          },
          body: JSON.stringify({
            model: "claude-3-sonnet-20240229",
            messages: [{
              role: "user",
              content: `From this data create two custom paragraphs explaining the state of the dementia patient. Output in JSON format: {paragraph_1: "...", paragraph_2: "..."}.
              
              Input Data:
              - Guardian Name: ${analysisPrompt.guardianName}
              - Patient Name: ${analysisPrompt.patientName}
              - Primary Intelligence: ${analysisPrompt.topIntelligence1} (${analysisPrompt.topIntelligence1Percentage})
              - Secondary Intelligence: ${analysisPrompt.topIntelligence2} (${analysisPrompt.topIntelligence2Percentage})
              
              Context for Primary Intelligence:
              ${analysisPrompt.patientContextQuestionsIntelligence1}
              
              Context for Secondary Intelligence:
              ${analysisPrompt.patientContextQuestionsIntelligence2}

              EXAMPLE PARAGRAPH 1, ensure you use only data from initial data presented, not this below paragraph. Use context information to form a paragraph with similar focus area.
              According to {guardianName}, {patientName/gender assumed} is an avid sports fan. He enjoyed baseball the
              most, as he played it throughout his childhood and college. He spent his career as a
              self-employed landscape architect and took up painting later in life, possessing a natural
              ability. {guardianName} reports that her husband's dementia symptoms are troublesome but
              manageable until about 3 pm, at which time he experiences severe confusion. {guardianName} also
              states that he has significant vision issues and needs to be reminded to wear his
              glasses.

              EXAMPLE PARAGRAPH 2, ensure you use only data from initial data presented, not this below paragraph. Use context information to form a paragraph with similar focus area.
              {patientName} possesses a high level of Interpersonal Intelligence (77%), meaning he interacts
              effectively with others. People possessing interpersonal intelligence are sensitive to
              others' moods, feelings, temperaments, and motivations. It is sometimes called social
              intelligence. Joe should be encouraged to participate in group activities and socialize
              when able.       
              `
            }],
            max_tokens: 3000
          })
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.status}`);
        }

        const result = await response.json();
        console.log('API Response:', result); // Debug log

        // Check if result has the expected structure
        if (!result.content || !Array.isArray(result.content) || !result.content[0]?.text) {
          throw new Error('Invalid API response format');
        }
          

        // Try to parse the response as JSON
        let analysis;
        try {
          analysis = JSON.parse(result.content[0].text);
          
          // Validate the analysis object has required properties
          if (!analysis.paragraph_1 || !analysis.paragraph_2) {
            throw new Error('Invalid analysis format');
          }
        } catch (parseError) {
          console.error('Failed to parse analysis:', parseError);
          throw new Error('Invalid JSON in response');
        }

        if (isSubscribed) {
          setAiAnalysis(analysis);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error in fetchAIAnalysis:', error);
        if (isSubscribed) {
          setIsLoading(false);
        }
      }
    };

    fetchAIAnalysis();

    
    return () => {
      isSubscribed = false;
      
    };
  });

  */

  const renderAnalysisTab = () => (
    <div>
      <h2 className="text-xl font-bold mb-6">Intelligence Analysis</h2>
      
      {isLoading ? (
        <div className="flex items-center justify-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
        </div>
      ) : aiAnalysis ? (
        <div className="space-y-8">
          {/* First Paragraph */}
          <div className="bg-white p-6 rounded-lg shadow">
            <p className="text-gray-700 leading-relaxed">{aiAnalysis.paragraph_1}</p>
          </div>
  
          {/* Horizontal Bar Chart */}
          <div className="h-[200px] mb-8">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={topIntelligences}
                layout="vertical"
                margin={{ top: 20, right: 30, left: 100, bottom: 20 }}
              >
                <XAxis type="number" domain={[0, 100]} />
                <YAxis 
                  type="category" 
                  dataKey="name" 
                  width={90}
                />
                <Tooltip />
                <Bar 
                  dataKey="percentage" 
                  fill="#8884d8"
                  label={{ 
                    position: 'right',
                    formatter: (value) => `${value.toFixed(1)}%`
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
  
          {/* Second Paragraph */}
          <div className="bg-white p-6 rounded-lg shadow">
            <p className="text-gray-700 leading-relaxed">{aiAnalysis.paragraph_2}</p>
          </div>
        </div>
      ) : (
        <div className="text-center text-gray-600">
          Failed to load AI analysis. Please try again later.
        </div>
      )}
    </div>
  );

  return (
      <div className="w-full">
          <div className="max-w-7xl mx-auto">
              {/* Back Button Section */}
              <div className="mb-6 flex items-center">
                  <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                          console.log('Back button clicked');
                          if (typeof onClose === 'function') {
                              onClose();
                          }
                      }}
                      className="px-6 py-3 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors duration-200 text-lg font-semibold flex items-center gap-2"
                  >
                      <ArrowLeft className="w-5 h-5" />
                      Back to Results
                  </motion.button>
              </div>

              {/* Header */}
              <div className="flex items-center justify-between mb-8">
                  <div className="flex items-center gap-4">
                      <img src="/cde-retina.png" alt="CDE Logo" className="h-16" />
                      <div>
                          <h1 className="text-2xl font-bold text-purple-900">
                              The Center for Holistic Dementia Care
                          </h1>
                          <p className="text-teal-600">MISPWD© Results</p>
                      </div>
                  </div>
              </div>

              {/* Client Info Card */}
              <div className="mb-8 bg-white rounded-lg shadow-lg">
                  <div className="p-6">
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                          <div>
                              <p className="text-sm text-gray-500">Name</p>
                              <p className="font-semibold">{clientInfo.name}</p>
                          </div>
                          <div>
                              <p className="text-sm text-gray-500">Respondent</p>
                              <p className="font-semibold">{clientInfo.respondent}</p>
                          </div>
                          <div>
                              <p className="text-sm text-gray-500">Surveyor</p>
                              <p className="font-semibold">{clientInfo.surveyor}</p>
                          </div>
                          <div>
                              <p className="text-sm text-gray-500">Date</p>
                              <p className="font-semibold">{clientInfo.date}</p>
                          </div>
                      </div>
                  </div>
              </div>

              {/* Tabs */}
              <div className="space-y-4">
                  <div className="grid w-full grid-cols-4 bg-white shadow-lg rounded-lg p-1 gap-1">
                      {tabItems.map(tab => (
                          <button
                              key={tab.id}
                              onClick={() => setActiveTab(tab.id)}
                              className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                                  activeTab === tab.id
                                      ? 'bg-purple-100 text-purple-900'
                                      : 'text-gray-500 hover:bg-purple-50 hover:text-purple-700'
                              }`}
                          >
                              <tab.icon className="w-4 h-4" />
                              {tab.label}
                          </button>
                      ))}
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
  {activeTab === "overview" && (
    <div>
      <h2 className="text-xl font-bold mb-4">Overview of MISPWD Survey Goals</h2>
      <p className="mb-6 text-gray-700">
        The Multiple Intelligence Survey for Persons with Dementia (MISPWD) eliminates the guesswork in care by identifying person-centered strategies, helping create the ideal care plan. The survey's most notable benefit is its ability to pinpoint the following:
      </p>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold text-purple-800 mb-2">Comfortable Environments:</h3>
          <p className="mb-2 text-gray-700">
            The MISPWD identifies intelligence-specific environments where people feel safe and secure, which:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Prevents overstimulation</li>
            <li>Improves sleep and appetite</li>
            <li>Decreases confusion</li>
            <li>Encourages social interaction</li>
            <li>And more</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-purple-800 mb-2">Augmentative and Alternative Communication (AAC) Strategies:</h3>
          <p className="mb-2 text-gray-700">
            The MISPWD recognizes intelligence-specific ways to help people better their communicative ability. Augmentative strategies help reinforce oral language for those who have trouble communicating verbally, while alternative methods provide nonverbal people with a means to relay information, which:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Ensures people's needs and wants are met</li>
            <li>Promotes independence</li>
            <li>Alleviates frustration</li>
            <li>Decreases confusion</li>
            <li>And more</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-purple-800 mb-2">Suitable Activities:</h3>
          <p className="mb-2 text-gray-700">
            The MISPWD identifies intelligence-specific activities that align with how people learn and acquire information throughout their lives, increasing their chances of success, which:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Heightens enjoyment</li>
            <li>Improves self-worth</li>
            <li>Motivates to remain cognitively engaged</li>
            <li>Promotes independence</li>
            <li>And more</li>
          </ul>
        </div>
      </div>
    </div>
  )}

            {activeTab === "analysis" /*&& renderAnalysisTab()*/}

            {activeTab === "recommendations" && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-blue-50 rounded-lg p-6">
                    <h3 className="text-xl font-bold text-blue-900 mb-4">Visual/Spatial Intelligence (80%)</h3>
                    <div className="space-y-4">
                      <div>
                        <h4 className="font-semibold text-blue-800">Comfortable Environment</h4>
                        <p className="text-blue-700">Visually stimulating surroundings such as flower gardens, bright rooms, sporting events.</p>
                      </div>
                      <div>
                        <h4 className="font-semibold text-blue-800">Suitable Activities</h4>
                        <ul className="list-disc pl-6 text-blue-700">
                          <li>Landscape puzzles</li>
                          <li>Sports matching game</li>
                          <li>Painting</li>
                          <li>Color Jenga</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="bg-yellow-50 rounded-lg p-6">
                    <h3 className="text-xl font-bold text-yellow-900 mb-4">Bodily-Kinesthetic Intelligence (85%)</h3>
                    <div className="space-y-4">
                      <div>
                        <h4 className="font-semibold text-yellow-800">Comfortable Environment</h4>
                        <p className="text-yellow-700">Areas that allow for movement such as courtyards, walking paths, and sports fields.</p>
                      </div>
                      <div>
                        <h4 className="font-semibold text-yellow-800">Suitable Activities</h4>
                        <ul className="list-disc pl-6 text-yellow-700">
                          <li>Movement games</li>
                          <li>Beginner Origami</li>
                          <li>Fidget devices</li>
                          <li>Walking</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-purple-50 rounded-lg p-6">
                  <h3 className="text-xl font-bold text-purple-900 mb-4">Additional Recommendations</h3>
                  <p className="text-purple-700">
                    Because the client experiences late-day confusion (Sundown Syndrome), we suggest he participate in morning and early afternoon activities. Additionally, to address vision issues, we recommend that he wears his prescription glasses and that activity supplies accommodate his needs, e.g. containing large print and bright solid colors to increase visibility.
                  </p>
                </div>
              </div>
            )}

            {activeTab === "intelligences" && (
              <div>
                <h2 className="text-xl font-bold mb-6">Eight Intelligences Results</h2>
                <div className="h-[400px] mb-8">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={allIntelligences} margin={{ top: 20, right: 30, left: 20, bottom: 70 }}>
                      <XAxis dataKey="name" angle={-45} textAnchor="end" height={80} />
                      <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft' }} />
                      <Tooltip />
                      <Bar dataKey="percentage" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                
                <div className="space-y-4">
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h3 className="font-semibold text-gray-700">Caveat</h3>
                    <p className="text-gray-600">The goal is to optimize care; just because a person scores higher on one intelligence versus another doesn't mean that he/she should not be exposed to other intelligence-aligned activities.</p>
                  </div>
                  
                  <div className="p-4 bg-gray-50 rounded-lg italic">
                    <h3 className="font-semibold text-gray-700">Disclaimer</h3>
                    <p className="text-gray-600">These results contain educational information. Please use it as a supplement rather than a replacement for medical advice, diagnosis or treatment.</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AICarePlan.propTypes = {
  data: PropTypes.shape({
      clientInfo: PropTypes.shape({
          name: PropTypes.string.isRequired,
          respondent: PropTypes.string.isRequired,
          surveyor: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired
      }).isRequired,
      intelligenceScores: PropTypes.arrayOf(
          PropTypes.shape({
              name: PropTypes.string.isRequired,
              percentage: PropTypes.number.isRequired
          })
      ),
      topIntelligences: PropTypes.arrayOf(
          PropTypes.shape({
              name: PropTypes.string.isRequired,
              percentage: PropTypes.number.isRequired
          })
      ),
      detailedAnswers: PropTypes.object
  }).isRequired,
  onClose: PropTypes.func.isRequired
};


export default AICarePlan;