// In your App.js file:
import React from 'react';
import SurveyApp from './QuizModule';
import './index.css';

function App() {
  return (
    <div className="font-sans">
      <SurveyApp />
    </div>
  );
}

export default App;