const surveyData = {
    "survey": {
      "title": "Multiple Intelligence Survey for Persons with Dementia (MISPWD)",
      "sections": [

        {
          "id": 1,
          "name": "Data Collection",
          "questions": [
            {
              "id": 1,
              "text": "Do you feel like the facility could improve on any of its practices? (group activities, communication methods, etc.) What would you suggest?",
              "type": "text",
              "placeholder": ""
            },
            {
              "id": 2,
              "text": "To what extent do you feel the facility staff is well-trained to address the needs of individuals with dementia? What makes you think so (in the following question)?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "strongly disagree",
              "maxLabel": "strongly agree"
            },
            {
              "id": 3,
              "text": "Please explain your answer for the above question in more detail. What makes you think so?",
              "type": "text",
              "placeholder": "Please explain in this textbox."
            },
            {
              "id": 4,
              "text": "Do you have any current concerns regarding your loved one’s care? What would you change about your loved one’s care?",
              "type": "text",
              "placeholder": "Please explain in this textbox."
            }
          ]
        },
        
        {
          "id": 2,
          "name": "Personal History",
          "questions": [
            {
              "id": 1,
              "text": "Does your loved one experience frustration, anxiety, agitation, or aggression?",
              "type": "boolean",
              "min": 1,
              "max": 5,
              "options": [
                "Never experiences these emotions",
                "Rarely experiences these emotions",
                "Sometimes experiences these emotions",
                "Often experiences these emotions",
                "Frequently experiences these emotions"
              ],
              "minLabel": "Never",
              "maxLabel": "Frequently"
            },
            {
              "id": "1A",
              "text": "Please describe any known causes of frustration, anxiety, agitation, or aggression.",
              "type": "text",
              "optional": true,
              "placeholder": "Describe any triggers or patterns you've noticed"
            },
            {
              "id": 2,
              "text": "What comforts your loved one?",
              "type": "text",
              "placeholder": "Examples include emotional support, validating feelings, reassurance, or physical aids like weighted blankets"
            },
            {
              "id": 3,
              "text": "Does your loved one enjoy being in the company of others or prefer to be alone?",
              "type": "text",
              "placeholder": "Describe their preferences for social interaction and solitude"
            },
            {
              "id": 4,
              "text": "What is your loved one's favorite color?",
              "type": "text",
              "placeholder": "Enter their preferred color"
            },
            {
              "id": 8,
              "text": "Does your loved one use any assistive devices?",
              "type": "multiselect",
              "placeholder": "Select all assistive devices used",
              "options": [
                "Glasses",
                "Hearing aid",
                "Walker",
                "Wheelchair",
                "Other",
                "None of the above"
              ]
            },
            {
              "id": "8A",
              "text": "If you selected Other, please specify:",
              "type": "text",
              "optional": true,
              "placeholder": "Describe other assistive devices"
            },
            {
              "id": 9,
              "text": "Does your loved one have trouble with any of the following?",
              "type": "multiselect",
              "placeholder": "Select all applicable mobility issues",
              "options": [
                "Upper-body mobility (problems with shoulders)",
                "Lower-body mobility (problems with hips, knees, ankles)",
                "Fine-motor skills (ability to use small muscles in hands and wrists)",
                "Eye-hand coordination",
                "Other",
                "None of the above"
              ]
            },
            {
              "id": "9A",
              "text": "If you selected Other, please specify:",
              "type": "text",
              "optional": true,
              "placeholder": "Describe other mobility issues"
            },
            {
              "id": 10,
              "text": "Does your loved one have sensitivity with any of their senses?",
              "type": "multiselect",
              "placeholder": "Select all sensory sensitivities",
              "options": [
                "Sight, e.g. bright or flashing lights",
                "Sound, like loud or background noise, barking dogs",
                "Taste, such as spicy or sour foods, hot or cold food or drink",
                "Smell, e.g. gasoline or cigarette smoke",
                "Touch, like certain textures or hand holding",
                "Other",
                "None of the above"
              ]
            },
            {
              "id": "10A",
              "text": "Explain your loved one's sensitivities (from above).",
              "type": "text",
              "optional": true,
              "placeholder": "Provide details about their sensory sensitivities"
            },
            {
              "id": 11,
              "text": "Is your loved one:",
              "type": "select",
              "placeholder": "Select verbal communication level",
              "options": [
                "Fully verbal",
                "Partially verbal",
                "Nonverbal",
                "Other",
                "None of the above"
              ]
            },
            {
              "id": 12,
              "text": "Is your loved one:",
              "type": "select",
              "placeholder": "Select attentiveness level",
              "options": [
                "Attentive",
                "Inattentive",
                "Other",
                "None of the above"
              ]
            },
            {
              "id": "12A",
              "text": "Describe your loved one's attentiveness.",
              "type": "text",
              "optional": true,
              "placeholder": "Provide details about their attention span and focus"
            },
            {
              "id": 13,
              "text": "If your loved one does experience late day confusion, what time does it occur?",
              "type": "text",
              "placeholder": "Example: Between 4-6 PM"
            },
            {
              "id": 14,
              "text": "Please list your loved one's top five favorite activities and state whether dementia has affected their experience with the activity.",
              "type": "text",
              "placeholder": "Example: Reading - they still enjoy it, but they have a hard time remembering what was read"
            },
            {
              "id": 15,
              "text": "What decade were they born:",
              "type": "select",
              "placeholder": "Select birth decade",
              "options": [
                "1920",
                "1930",
                "1940",
                "1950",
                "1960",
                "1970",
                "Other"
              ]
            },
            {
              "id": "15A",
              "text": "If other, please specify the year.",
              "type": "text",
              "optional": true,
              "placeholder": ""
            },
            {
              "id": 16,
              "text": "Please elaborate on your loved one's preferred activities from the era in which they lived.",
              "type": "text",
              "placeholder": "Examples: specific music, dances, fashion trends, or activities from their era"
            },
            {
              "id": 17,
              "text": "What are your loved one's current likes and dislikes?",
              "type": "text",
              "placeholder": "List current preferences, activities they enjoy or avoid"
            },
            {
              "id": 19,
              "text": "What languages does your loved one speak? Please list from their first language onwards.",
              "type": "text",
              "placeholder": "Example: English (first), Spanish (second)"
            },
            {
              "id": 20,
              "text": "What was your loved one's occupation (including homemaker), including if they were in the military (service/branch)?",
              "type": "text",
              "placeholder": "Example: Teacher for 30 years, Army veteran (1960-1964)"
            },
            {
              "id": 21,
              "text": "What were your loved one's favorite places?",
              "type": "text",
              "placeholder": "Examples: vacation spots, hometown locations, parks, or countries visited"
            },
            {
              "id": 22,
              "text": "If he/she went to college, what was his/her major?",
              "type": "text",
              "optional": true,
              "placeholder": "Enter college major or field of study"
            },
            {
              "id": 23,
              "text": "Would your loved one enjoy a faith-based activity?",
              "type": "boolean"
            },
            {
              "id": 24,
              "text": "What is your loved one's favorite meal?",
              "type": "text",
              "placeholder": "Describe their preferred foods, dishes, or meals"
            }
          ]
        },
        {
          "id": 3,
          "name": "Logical-Mathematical Intelligence",
          "questions": [
            {
              "id": 1,
              "text": "How much did your loved one enjoy solving math problems?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "hated it",
              "maxLabel": "loved it"
            },
            {
              "id": "1A",
              "text": "Record math solving activities that they enjoyed, e.g. Sudoku, calculations, and/or word problems.",
              "type": "text",
              "optional": true
            },
            {
              "id": 2,
              "text": "How good was your loved one at identifying trends and patterns?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "2A",
              "text": "Record trends and patterns they enjoyed identifying, e.g. financial analysis, market research, or monitoring social media platforms.",
              "type": "text",
              "optional": true
            },
            {
              "id": 3,
              "text": "How good was your loved one at identifying relationships among things?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "3A",
              "text": "Record relationships they enjoyed identifying, e.g. sorting/categorizing, analogies, flowcharts, mapping, and/or playing logic/strategy games.",
              "type": "text",
              "optional": true
            },
            {
              "id": 4,
              "text": "How good was your loved one at understanding abstract ideas?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "4A",
              "text": "Record ways that they enjoyed understanding abstract ideas, e.g. allegories, symbolism, conceptual design, storytelling, or simulation and role-playing games.",
              "type": "text",
              "optional": true
            },
            {
              "id": 5,
              "text": "How good was your loved one at putting things in sequential order?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "5A",
              "text": "Record ways that they enjoyed putting things in sequential order, e.g. timelines, puzzles, recipes, or assembling.",
              "type": "text",
              "optional": true
            },
            {
              "id": 6,
              "text": "Did your loved one have a profession that involved science, math, finance, computers, technology, banking, or the economy?",
              "type": "boolean"
            },
            {
              "id": "6A",
              "text": "Add information about the previous question.",
              "type": "text",
              "optional": true
            }
          ]
        },
        {
          "id": 4,
          "name": "Linguistic-Verbal Intelligence",
          "questions": [
            {
              "id": 1,
              "text": "How good was your loved one's finesse/acuity with words?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "1A",
              "text": "Record ways that they enjoyed demonstrating their finesse or acuity with words, e.g. playing Scrabble, Jumble, Pictionary, Taboo, Balderdash, Hangman, or doing anagrams/word association.",
              "type": "text",
              "optional": true
            },
            {
              "id": 2,
              "text": "How comfortable was your loved one with speaking and writing?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "not at all",
              "maxLabel": "extremely"
            },
            {
              "id": "2A",
              "text": "Record ways that they enjoyed speaking and writing, e.g. proficiency in grammar, punctuation, spelling; effective persuasive techniques; participation in debates or public speaking.",
              "type": "text",
              "optional": true
            },
            {
              "id": 3,
              "text": "How good was your loved one at using words to achieve objectives (persuasion/marketing/debate)?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "3A",
              "text": "Record ways that they enjoyed using words to achieve objectives, e.g. proficiency at visual and oral presentations; facilitating discussions, negotiating, or writing and giving speeches.",
              "type": "text",
              "optional": true
            },
            {
              "id": 4,
              "text": "How good was your loved one's vocabulary?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "4A",
              "text": "Record ways that they excelled at or enjoyed vocabulary, e.g. adept articulation, high scores on exams; publications; familiarity with word roots, prefixes and suffixes.",
              "type": "text",
              "optional": true
            },
            {
              "id": 5,
              "text": "How much did your loved one enjoy reading and writing?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "not at all",
              "maxLabel": "extremely"
            },
            {
              "id": "5A",
              "text": "Record ways that they enjoyed reading or writing, e.g. attending book talks/literary festivals; journaling; blogging; reading; or entering writing competitions.",
              "type": "text",
              "optional": true
            },
            {
              "id": 6,
              "text": "Historically, did your loved one have a profession involving linguistics such as: public speaker, librarian, politician, radio/tv announcer/host, YouTuber, journalist, lawyer, curator, speech pathologist, writer, sales?",
              "type": "boolean"
            },
            {
              "id": "6A",
              "text": "Add information about the previous question.",
              "type": "text",
              "optional": true
            }
          ]
        },
        {
          "id": 5,
          "name": "Interpersonal Intelligence",
          "questions": [
            {
              "id": 1,
              "text": "How good was your loved one at sensing other people's emotions and reading their motives?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "1A",
              "text": "Record ways that they enjoyed sensing other people's emotions and reading their motives, e.g. being empathetic; using observational awareness; or demonstrating adaptability.",
              "type": "text",
              "optional": true
            },
            {
              "id": 2,
              "text": "How comfortable was your loved one at meeting new people?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "2A",
              "text": "Record ways that they were good at meeting new people, e.g. initiating conversations; ease in group settings; demonstrating confidence; or making others comfortable.",
              "type": "text",
              "optional": true
            },
            {
              "id": 3,
              "text": "How good was your loved one at group cohesiveness, leadership, participating in organizations, and/or promoting solidarity?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "3A",
              "text": "Record ways that they were good at participating in groups, acting as a leader, being part of an organization or promoting solidarity.",
              "type": "text",
              "optional": true
            },
            {
              "id": 4,
              "text": "How good was your loved one's ability to look at things from different perspectives?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "4A",
              "text": "Record ways that they were good at looking at things from different perspectives, e.g. demonstrations of open-mindedness and empathy; willingness to adjust based on flexibility in thinking; or being culturally sensitive.",
              "type": "text",
              "optional": true
            },
            {
              "id": 5,
              "text": "How good was your loved one at understanding other people's point of view?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "5A",
              "text": "Record ways that they were good at understanding other people's point of view, e.g. use of empathy; respect for diversity; or creating inclusive environments.",
              "type": "text",
              "optional": true
            },
            {
              "id": 6,
              "text": "Was your loved one involved in one of these careers: human resources, counselor, management, psychologist, public relations, social director, teacher or social worker?",
              "type": "boolean"
            },
            {
              "id": "6A",
              "text": "Add information or examples about the previous question.",
              "type": "text",
              "optional": true
            }
          ]
        },
        {
          "id": 6,
          "name": "Intrapersonal Intelligence",
          "questions": [
            {
              "id": 1,
              "text": "How good was your loved one's ability to access his/her own feelings and/or range of emotions?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "not at all",
              "maxLabel": "extremely"
            },
            {
              "id": "1A",
              "text": "Give evidence of this ability with examples from language, music, or some other more expressive form of intelligence (journaling, meditation, participating in therapy/counseling).",
              "type": "text",
              "optional": true
            },
            {
              "id": 2,
              "text": "How self-motivated was your loved one?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "not at all",
              "maxLabel": "extremely"
            },
            {
              "id": "2A",
              "text": "Record ways that they were good at self-motivation, e.g. setting and achieving goals; engaging in continuous learning; networking; or engaging in financial planning and budgeting.",
              "type": "text",
              "optional": true
            },
            {
              "id": 3,
              "text": "How capable was your loved one to put him/herself first when necessary?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "not at all",
              "maxLabel": "extremely"
            },
            {
              "id": "3A",
              "text": "Record ways that they were good at putting him/herself first when necessary, e.g. engaging in self-care practices; setting boundaries; valuing personal time; or setting personal goals.",
              "type": "text",
              "optional": true
            },
            {
              "id": 4,
              "text": "How independent was your loved one?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "not at all",
              "maxLabel": "extremely"
            },
            {
              "id": "4A",
              "text": "Record ways that they were independent, e.g. living alone; traveling solo, being financially independent; engaging in personal growth; advocating for oneself; or taking responsibility for personal health and well-being.",
              "type": "text",
              "optional": true
            },
            {
              "id": 5,
              "text": "How much did your loved one enjoy taking time for self-reflection?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "not at all",
              "maxLabel": "extremely"
            },
            {
              "id": "5A",
              "text": "Record ways that they were good at taking time for self-reflection, e.g. journaling; setting personal goals; reading and learning; engaging in meditation; or participating in therapy/ counseling.",
              "type": "text",
              "optional": true
            },
            {
              "id": 6,
              "text": "Did your loved one have one of these careers: psychologist, writer, therapist, counselor, social worker, theologian, entrepreneur or poet?",
              "type": "boolean"
            },
            {
              "id": "6A",
              "text": "Add information or examples about the previous question.",
              "type": "text",
              "optional": true
            }
          ]
        },
        {
          "id": 7,
          "name": "Musical Intelligence",
          "questions": [
            {
              "id": 1,
              "text": "How enhanced was your loved one's ability to sense rhythm and sound?",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "1A",
              "text": "Record ways that they were good at sensing rhythm and sound, e.g. listening, clapping, playing an instrument, or singing.",
              "type": "text",
              "optional": true
            },
            {
              "id": 2,
              "text": "Rank your loved one's ability to create music.",
              "type": "scale",
              "min": 1,
              "max": 5,
              "minLabel": "poor",
              "maxLabel": "superior"
            },
            {
              "id": "2A",
              "text": "Record ways that they were good at creating music, e.g. songwriting, band practice, or karaoke.",
              "type": "text",
              "optional": true
            },

          {
            "id": 3,
            "text": "What was your loved one's ability to recognize, create or reflect on music, and love doing so?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "3A",
            "text": "Record ways that they were good at recognizing, creating or reflecting on music, e.g. listening to music, taking music appreciation classes, playing an instrument, or writing music.",
            "type": "text",
            "optional": true
          },
          {
            "id": 4,
            "text": "What was your loved one's comfortable level with music playing in the background while doing other things?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "4A",
            "text": "Record ways that they were comfortable with background music, e.g. music in restaurants, retail stores, hotels, bars, public transportation, fitness centers, offices, or parties.",
            "type": "text",
            "optional": true
          },
          {
            "id": 5,
            "text": "What was your loved one's sensitivity to sounds, and hearing what others miss?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "5A",
            "text": "Record ways that they were sensitive to sounds, e.g. heightened reactions to sounds, discomfort or irritation to sounds, difficulty filtering noise, or preference for silence.",
            "type": "text",
            "optional": true
          },
          {
            "id": 6,
            "text": "Did your loved one have one of these careers: conductor, musician, piano teacher, composer, dance teacher, music therapist or choral director?",
            "type": "boolean"
          },
          {
            "id": "6A",
            "text": "Add information or examples about the previous question.",
            "type": "text",
            "optional": true
          }
        ]
      },
      {
        "id": 8,
        "name": "Visual-Spatial Intelligence",
        "questions": [
          {
            "id": 1,
            "text": "How well could your loved one produce an image or picture without an external stimulus, drawing from memories or previous experience?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "1A",
            "text": "Record ways that they were good at producing images without an external stimulus, e.g. storyboarding, playing Pictionary, memory recall, or creative writing.",
            "type": "text",
            "optional": true
          },
          {
            "id": 2,
            "text": "Rank your loved one's artistic skills, being able to create artwork, including graphic skills.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "2A",
            "text": "Record ways that they were good at creating art, e.g. illustration, Pictionary, Telestrations, animation, visual effects, or photography.",
            "type": "text",
            "optional": true
          },
          {
            "id": 3,
            "text": "Rank your loved one's awareness of his/her surrounding environment.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "3A",
            "text": "Record ways that they had a good awareness of the surrounding environment, e.g. navigation, sports, forestry, piloting, detection, or yoga.",
            "type": "text",
            "optional": true
          },
          {
            "id": 4,
            "text": "Rank your loved one's sense of direction.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "4A",
            "text": "Record ways that they had a good sense of direction, e.g. navigating, orientation skills, remembering routes and landmarks, or estimation.",
            "type": "text",
            "optional": true
          },
          {
            "id": 5,
            "text": "What was your loved one's level of enjoyment playing jigsaw puzzles and games based around navigation?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "5A",
            "text": "Record ways that they were good at puzzles and navigation, e.g. Geocaching, letterboxing, escape rooms, treasure hunts, mazes, or scavenger hunts.",
            "type": "text",
            "optional": true
          },
          {
            "id": 6,
            "text": "Did your loved one have one of these careers: architect, geometry teacher, engineer, surveyor, urban planner, graphic artist, interior decorator, photographer, pilot or cartographer?",
            "type": "boolean"
          },
          {
            "id": "6A",
            "text": "Add information or examples about the previous question.",
            "type": "text",
            "optional": true
          }
        ]
      },
      {
        "id": 9,
        "name": "Bodily-Kinesthetic Intelligence",
        "questions": [
          {
            "id": 1,
            "text": "How adept was your loved one at using his/her physicality to manipulate objects and other elements around him/her (similar to athletic ability)?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "1A",
            "text": "Record ways that they were good at using his/her physicality to manipulate objects and elements, e.g. Jenga, building blocks, constructing, or sports like basketball, soccer, volleyball, or tennis.",
            "type": "text",
            "optional": true
          },
          {
            "id": 2,
            "text": "How capable was your loved one at communicating well using body language, using gestures and actions to convey his/her message?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "2A",
            "text": "Record ways that they were good at communicating using body language, e.g. Charades, pantomime, improv theater, or role playing.",
            "type": "text",
            "optional": true
          },
          {
            "id": 3,
            "text": "How did your loved one feel about physical contact?",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "3A",
            "text": "Record ways that they were receptive to physical contact, hugging, handshaking, or massages.",
            "type": "text",
            "optional": true
          },
          {
            "id": 4,
            "text": "Rank your loved one's sense of timing when it comes to physical tasks and activities.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "4A",
            "text": "Record ways that they were good with their sense of timing, e.g. coordination, reaction time, pacing, speed and pace, or precision and accuracy.",
            "type": "text",
            "optional": true
          },
          {
            "id": 5,
            "text": "Rank your loved one's ability to handle objects with a high degree of deftness, control and economy of movement.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "5A",
            "text": "Record ways that they were good at handling objects with deftness, control and economy of movement, e.g. playing a musical instrument, surgical procedures, typing, athleticism, cooking, model making, craftsmanship, or dance.",
            "type": "text",
            "optional": true
          },
          {
            "id": 6,
            "text": "Did your loved one have one of these careers: physical therapist, dancer, athlete, coach, fitness instructor, gym owner, actor, mechanic or carpenter?",
            "type": "boolean"
          },
          {
            "id": "6A",
            "text": "Add information or examples about the previous question.",
            "type": "text",
            "optional": true
          }
        ]
      },
      {
        "id": 10,
        "name": "Naturalistic Intelligence",
        "questions": [
          {
            "id": 1,
            "text": "Rank your loved one's ability to read and understand nature.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "1A",
            "text": "Record ways that they were good at reading and understanding nature, e.g. birding, animal tracking, weather prediction, gardening, wildlife conservation, or nature photography.",
            "type": "text",
            "optional": true
          },
          {
            "id": 2,
            "text": "Rank your loved one's love of nature and spending time outdoors.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "low",
            "maxLabel": "high"
          },
          {
            "id": "2A",
            "text": "Record ways that they loved nature or spending time outdoors, e.g. hiking, kayaking, camping, fishing, gardening, or wilderness survival.",
            "type": "text",
            "optional": true
          },
          {
            "id": 3,
            "text": "Rank your loved one's connection with animals.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "3A",
            "text": "Record ways that they loved connecting with animals, e.g. pet ownership, pet advocacy, wildlife observation, pet therapy, or volunteering at animal shelters.",
            "type": "text",
            "optional": true
          },
          {
            "id": 4,
            "text": "Rank your loved one's ability to raise or take care of animals and plants.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "poor",
            "maxLabel": "superior"
          },
          {
            "id": "4A",
            "text": "Record ways that they were good at raising or taking care of animals and plants, e.g. adaptability and resourcefulness in adjusting care routines or environments, participation in conservation and sustainability, pet sitter, neighborhood garden.",
            "type": "text",
            "optional": true
          },
          {
            "id": 5,
            "text": "Rank your loved one's desire to participate in outdoor activities and/or sports.",
            "type": "scale",
            "min": 1,
            "max": 5,
            "minLabel": "low",
            "maxLabel": "high"
          },
          {
            "id": "5A",
            "text": "Record ways that they participated in outdoor activities (fishing, hiking, swimming, birding, gardening, sailing, kayaking, etc.)",
            "type": "text",
            "optional": true
          },
          {
            "id": 6,
            "text": "Did your loved one have one of these careers: botanist, oceanographer, camp counselor, scout troop leader, gardener, astronomer, meteorologist, geologist or landscape architect?",
            "type": "boolean"
          },
          {
            "id": "6A",
            "text": "Add information about the previous question.",
            "type": "text",
            "optional": true
          }
        ]
      }
    ],
    "additionalQuestions": [
      {
        "id": 1,
        "text": "Please include any questions or other information that you would like to share with me regarding the care of your loved one.",
        "type": "text"
      }
    ]
  }
};

export default surveyData;